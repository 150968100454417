<template>
    <section id="introduction">
        <div style="min-height: 100vh" class="d-flex  align-items-center">
            <div class="text-start">
                <div>
                    <h1 class="text-info font-fira-code font-md">Hi, my name is</h1>
                    <h1 class="text-lightest-slate h1-xxl">Hossein Shakeri.</h1>
                    <h1 class="h1-xxl">I Build things for the web.</h1>
                    <p style="max-width:450px" class="font-md">
                        I'm a fullstack developer with <span class="text-info text-uppercase "> TREE </span> years of
                        experience building web
                        applications. I specialize in <span class="text-info text-uppercase"> full-stack </span>
                        development,
                        and have worked with a
                        variety of technologies, including
                        <span class="text-info text-uppercase"> C#</span>,
                        <span class="text-info text-uppercase"> JAVA</span>,
                        <span class="text-info text-uppercase">JS</span>,
                        <span class="text-info text-uppercase"> VUE.JS</span>,
                        and etc... .
                    </p>
                </div>
                <div class="mt-4">
                    <a href="mailto:h.shaker2001@gmail.com ">
                        <button class="btn btn-outline-info font-fira-code">contact me</button>
                    </a>
                </div>
            </div>
        </div>

    </section>
</template>

<script setup>

</script>

<style scoped>

</style>